/**
 * Import and load Foundation
 *
 * Foundation was built with UMD modules, which means we can extract the
 * components from node_modules.
 *
 * Read More:
 * https://foundation.zurb.com/sites/docs/javascript.html#import-in-javascript
 *
 * Component List found here:
 * node_modules/foundation-sites/js/foundation.js
 */
import {
  Foundation,  // Includes everything
  // Abide,
  // Accordion,
  // AccordionMenu,
  // CoreUtils,
  // Box,
  // onImagesLoaded,
  // Keyboard,
  // MediaQuery,
  // Motion, Move,
  // Nest,
  // Timer,
  // Touch,
  // Triggers,
  // Drilldown,
  // Dropdown,
  // DropdownMenu,
  // Equalizer,
  // Interchange,
  // Magellan,
  // OffCanvas,
  // Orbit,
  // ResponsiveMenu,
  // ResponsiveToggle,
  // Reveal,
  // Slider,
  // SmoothScroll,
  // Sticky,
  // Tabs,
  // Toggler,
  // Tooltip,
  // ResponsiveAccordionTabs,
 } from 'foundation-sites';
import jquery from 'jquery';

window.jQuery = jquery;
window.$ = jquery;

Foundation.addToJquery($);

jQuery(document).ready($ => ($(document).foundation()));
