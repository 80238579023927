/**
 * Load Foundation
 */
import foundation from './vendor/init-foundation';





/**
 * Lazyloading
 *
 * Reference: https://github.com/ApoorvSaxena/lozad.js?files=1
 */
import lozad from 'lozad'; // Lazy Loader

window.addEventListener('load', function() {
    const observer = lozad();
    observer.observe();  // Takes optional configuration object
});






/**
 * Fort Awesome
 *
 * Install the core DOM watcher
 * yarn add @fortawesome/fontawesome-svg-core
 *
 * Install the node package for whichever type of icon set you want to use
 * by copying and pasting one of the lines below.
 *
 * Note: The style can be found in a few places
 *     - On the font awesome website, look under the name or in the url
 *     - The class name has fas, fab, far, which lines up with the first letter of the type
 *         ex: fas = free-solid-svg,
 *             fab = free-brand-svg
 *
 * yarn add @fortawesome/free-solid-svg-icons
 * yarn add @fortawesome/free-regular-svg-icons
 * yarn add @fortawesome/free-brands-svg-icons
 * yarn add @fortawesome/pro-solid-svg-icons
 * yarn add @fortawesome/pro-regular-svg-icons
 * yarn add @fortawesome/pro-light-svg-icons
 */
// Note: import the needed Font Awesome functionality
// import { library, dom } from '@fortawesome/fontawesome-svg-core';

// Note: import the Facebook and Twitter icons
// import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

// Note: add the imported icons to the library
// library.add(faFacebook, faTwitter);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
// dom.watch();
